import { Route } from "@solidjs/router";
import { ROUTES } from "../const";
import { AboutPage, BlogPage, ContactPage, StagesPage, Home, JobsPage, SchedulesPage, TeamPage } from "../views";
import { MicrositeRoutesViewProps } from "../model";

export function MicrositeRoutes(props: MicrositeRoutesViewProps) {
  return (
    <>
      <Route path={ROUTES.home} element={<Home {...props} />} />
      <Route path={ROUTES.about} element={<AboutPage />} />
      <Route path={ROUTES.courses} element={<StagesPage />} />
      <Route path={ROUTES.teams} element={<TeamPage />} />
      <Route path={ROUTES.schedules} element={<SchedulesPage />} />
      <Route path={ROUTES.jobs} element={<JobsPage />} />
      <Route path={ROUTES.blog} element={<BlogPage />} />
      {/* <Route path={ROUTES.blog_post} element={<BlogPostPage />} /> */}
      <Route path={ROUTES.contact} element={<ContactPage {...props} />} />
    </>
  );
}
